let loadingCount = 0
let loadingTimer = null
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { showToast, showFailToast } from 'vant'
import i18n from "@/lang";
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard()


const startLoading = () => {
    store.dispatch('setLoadingShow', true)
}

const endLoading = () => {
    store.dispatch('setLoadingShow', false)
}

export const showLoading = () => {
    clearTimeout(loadingTimer)
    loadingTimer = setTimeout(() => {
        loadingCount = 1
        hideLoading()
    }, 30000)
    if (loadingCount === 0) {
        startLoading()
    }
    loadingCount += 1
}

export const hideLoading = () => {
    if (loadingCount <= 0) {
        return
    }
    loadingCount -= 1
    if (loadingCount === 0) {
        clearTimeout(loadingTimer)
        endLoading()
    }
}
// https://www.techonthenet.com/js/language_tags.php
// https://toutiao.io/posts/9uum6d/preview
export const langList = [
    {
        label: '繁體中文',
        code: 'zh-TW',
        subCode: ['zh-HK', 'zh-MO', 'zh-TW'],
    },
    {
        label: '简体中文',
        code: 'zh-CN',
        subCode: ['zh', 'zh-CN', 'zh-SG'],
    },
    {
        label: '日本語',
        code: 'ja-JP',
        subCode: ['ja', 'ja-JP'],
    },
    {
        label: 'English',
        code: 'en-US',
        subCode: ['en', 'en-AU', 'en-BZ', 'en-CA', 'en-CB', 'en-IE', 'en-JM', 'en-NZ', 'en-PH', 'en-ZA', 'en-TT', 'en-GB', 'en-US', 'en-ZW'],
    }
]

// https://www.techonthenet.com/js/language_tags.php
export const getLang = (id) => {
    // 获取当前的merchantLangList
    const merchantLangMapStr = localStorage.getItem('merchantLangMap')
    let merchantLangMap = {}
    if (merchantLangMapStr) {
        try {
            merchantLangMap = JSON.parse(merchantLangMapStr)
        } catch (error) {
            console.warn(error)
        }
    }
    return merchantLangMap[id] || null
}

// setLang
export const setLang = (lang, id) => {
    // 获取当前的merchantLangList
    const merchantLangMapStr = localStorage.getItem('merchantLangMap')
    let merchantLangMap = {}
    if (merchantLangMapStr) {
        try {
            merchantLangMap = JSON.parse(merchantLangMapStr)
        } catch (error) {
            console.warn(error)
        }
    }
    merchantLangMap[id] = lang
    localStorage.setItem('merchantLangMap', JSON.stringify(merchantLangMap))
}

// html的img标签，src属性的值是相对路径，需要拼接上服务器地址
// 注意有重复路径的图片时会有问题
export const formatImgSrc = (html) => {
    const pathAssets = process.env.VUE_APP_PATH_ASSETS
    const domain = store.getters.merchant?.base?.domain || '/'
    const reg = /<img.*?(?:>|\/>)/gi
    const regSrc = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
    const imgs = html.match(reg)
    const replacedSrc = []
    if (imgs) {
        for (let i = 0; i < imgs.length; i++) {
            const src = imgs[i].match(regSrc)
            if (src && src[1]) {
                // Check if src already contains a domain
                if (!src[1].startsWith('http://') && !src[1].startsWith('https://') && !replacedSrc.includes(src[1])) {
                    // If not, prepend the domain
                    const srcToReplace = new RegExp(src[1], 'g');
                    html = html.replace(srcToReplace, domain + pathAssets + src[1]);
                    replacedSrc.push(src[1])
                }
            }
        }
    }
    return html
}

export const getDistanceString = (distance = 0, isKm = true) => {
    console.log(distance)
    if (isKm) {
        distance = parseInt(distance * 1000)
    }
    if (distance < 1000) {
        distance = distance + 'm'
    } else {
        distance = Math.round(distance / 100) / 10 + 'km'
    }
    return distance
}

export const downloadImage = async (imageSrc) => {
    try {
        // fetchで画像データを取得
        const image = await fetch(imageSrc);
        const imageBlob = await image.blob();
        const imageURL = URL.createObjectURL(imageBlob);

        // 拡張子取得
        const mimeTypeArray = imageBlob.type.split('/');
        const extension = mimeTypeArray[1];

        // ダウンロード
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = `fileName.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        throw new Error(`${error}. Image src: ${imageSrc}`);
    }
}

export const goToGoogleMap = (lat, lng) => {
    // https://www.google.com/maps/search/?api=1&query=36.26577,-92.54324
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    window.open(url, '_blank')
}

// 获取本地坐标
export const getLocation = () => {
    const promise = new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            showLoading()
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    resolve(pos)
                    hideLoading()
                },
                (err) => {
                    console.log(err)
                    reject(err)
                    hideLoading()
                }
            )
        } else {
            console.warn('Geolocation is not supported by this browser.')
            reject('Geolocation is not supported by this browser.')
        }
    })
    return promise
}

export const setLastRoute = (route) => {
    const routeData = {
        name: route.name,
        params: route.params,
        query: route.query,
    }
    sessionStorage.setItem('lastRoute', JSON.stringify(routeData))
}
export const getLastRoute = () => {
    try {
        const route = JSON.parse(sessionStorage.getItem('lastRoute'))
        // 清空
        sessionStorage.removeItem('lastRoute')
        return route
    } catch (error) {
        console.warn(error)
    }
    // 清空
    sessionStorage.removeItem('lastRoute')
    return null
}
// 解析token jwt的exp 是否过期
export const tokenIsExp = (jwt) => {
    if (!jwt) {
        return true
    }
    const token = jwt.split('.')[1]
    const tokenObj = JSON.parse(atob(token))
    const exp = tokenObj.exp
    const now = Math.floor(Date.now() / 1000)
    console.log(exp, now)
    return exp < (now + 60) // check if token is going to expire in the next minute
}

export const onClickHtml = (e) => {
    const target = e.target
    const PleaseOpenInMiniProgram = i18n.global.t('m.common.PleaseOpenInMiniProgram')
    if (target.tagName === 'A') {
        console.log('Link clicked:', target)
        const dataJumpType = target.getAttribute('data-jump-type')
        const path = target.getAttribute('data-path')
        if (dataJumpType) {
            // 禁止a标签默认跳转
            e.preventDefault()
        }
        // app,page,phone,link
        switch (dataJumpType) {
            case 'app':
            case 'page':
                // 新窗口中打开
                showToast(PleaseOpenInMiniProgram)
                break
            case 'phone':
                // 拨打电话
                window.open(`tel:${path}`, '_blank')
                break
            case 'link':
                // 访问链接
                window.open(path, '_blank')
                // toClipboard(path).then(() => {
                //     showToast(i18n.global.t('m.common.copySuccess'))
                // }).catch(() => {
                //     showFailToast(i18n.global.t('m.common.copyFail'))
                // })
                break

            default:
                break
        }
    }
}

export const copyText = (text) => {
    toClipboard(text).then(() => {
        showToast(i18n.global.t('m.common.copySuccess'))
    }).catch(() => {
        showFailToast(i18n.global.t('m.common.copyFail'))
    })
}

// 定义版本
export const version = '0.0.1'